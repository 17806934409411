<template>
  <div class="box">
    <div class="tipMsg">{{ item.type === 1 ? '单项选择题' : (item.type === 2 ? '多项选择题' : '简答题') }}</div>
    <!-- 单选题 -->
    <div v-if="item.type === 1 || item.type === 2" class="choice-Question">
      <!-- {{ item.title }} -->
      <!-- 题目内容 -->
      <div v-html="item.title"></div>
      <div v-show="item.type === 1">
        <el-radio-group v-model="item[selectedIndex]" style="display: grid; margin: 25px;">
          <el-radio v-for="(items, index) in item.topicOptions" :label="index" :key="items.id"
            @change="chooseAnswer(index, item.id, items, item.isClick)" style="margin: 5px 0;">
            {{ items.opt }}、{{ items.value }}
          </el-radio>
        </el-radio-group>
        <!-- <div v-for="(items, index) in item.topicOptions" :key="index" @click="chooseAnswer(index, item.id, items.opt)">
          {{ items.opt }}、{{ items.value }}
        </div> -->
      </div>
      <!-- 多选题 -->
      <div v-show="item.type === 2">
        <el-checkbox-group v-model="selectedOptions" style="display: grid; margin: 25px;">
          <el-checkbox v-for="(items, index) in item.topicOptions" :label="items.id" :key="items.id"
            style="margin: 5px 0;" @change="chooseAnswerChoice(items, index, item)">
            {{ items.opt }}、{{ items.value }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <!-- 简答题 -->
    <div v-if="item.type === 3" class="choice-Question shortAnswer-Question">
      <!-- 上半部分 -->
      <div class="top">
        <div class="title">
          {{ title }}
        </div>
        <!-- 题目内容 -->
        <div class="topic">
          <span v-html="item.title"></span>
        </div>
      </div>
      <!-- 下半部分 -->
      <div class="bottom">
        <!-- <div class="seedTopic">
          {{ aaa }}
        </div> -->
        <div class="answerArea">
          <div style="border: 1px solid #ccc;">
            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
            <Editor style="height: 202px; overflow-y: hidden;" v-model="item.briefAnswer" :defaultConfig="editorConfig"
              :mode="mode" @onCreated="onCreated" @onChange="onChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { DomEditor } from '@wangeditor/editor'

export default {
  name: 'topiceMessage',
  components: { Editor, Toolbar },
  props: {
    dataSource: {
      type: Array
    },
  },
  data() {
    return {
      tips: '1.本试卷的试题...', // 题目上面的提示
      aaa: '现代项目管理过程中', // 题目内容(模拟)
      subjectInfo: {}, // 一道题目的信息
      subjectIndex: 0, // 做到第几道题目的索引
      // 单选
      selectedIndex: 0, // 当前题目的索引
      confirmButton: true,
      myOption: '',
      optionData: [], //保存用户输入的答案
      // 多选题
      selectedOptions: [], // 用于存储每个题目选中的选项，以二维数组形式存储
      answers: [], // 保存选中的选项
      // 简答题
      title: '（一）(29分)',
      topic: '[说明] aslkdalskjdlkasjdlkasjd阿拉山口大家拉萨空间的拉克丝角度来看阿斯兰的空间阿斯利康大家啊爱神的箭 ',
      answerArea: '富文本区域',
      item: {
        briefAnswer: '', // 简答题答案
      },
      // 富文本
      questionList: [], // 题目数组
      editor: null, // 实例
      html: '<p></p>',
      // 工具栏配置
      toolbarConfig: {
        // 隐藏哪些菜单
        excludeKeys: [
          '|', // 分割线
          "color", // 字体颜色
          'group-image', // 上传图片
          'group-video', // 上传视频
          "codeBlock",
          "divider",
          "bgColor",
          "lineHeight",
          "todo", // 代办
          "emotion", // 表情
          "insertLink", //插入链接
          "fontSize", // 字体大小
          "fontFamily", // 字体样式
        ],

      },
      editorConfig: {
        placeholder: '请输入内容...',
        hoverbarKeys: {
          text: {
            menuKeys: [
              "bold", // 加粗
              "italic", // 斜体
              "underline", // 下划线
              "bulletedList", // 无序列表
              "numberedList", // 有序列表
              "through", // 删除线
              "clearStyle", // 清除格式
            ]
          },
        }
      },
      mode: 'default', // or 'simple'
      editorConfig: {                        // JS 语法
        /* 编辑器配置 */
        // 显示哪些菜单，如何排序、分组
        toolbarKeys: [

        ]
      },
    }
  },
  mounted() {
    // 监听一个自定义事件 切题
    this.$bus.$on('event', (data) => {
      console.log(data);
      this.item = data.item || data;
      console.log('markers', this.item);
      console.log('markers', this.item.markers);
    });
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.item.briefAnswer = ''
    }, 1500)
    // 获取题目数据的方法
    // this.fetchQuestionData()
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
    this.$bus.$off('event')
    this.$bus.$off('data-updated')
  },
  created() {
    // 在 created 生命周期钩子函数中监听事件
    this.$bus.$on('data-updated', this.handleDataUpdated);
  },
  coomputed: {
    option() {
      // 返回当前题目对象
      console.log(data);
      console.log(data.index);
      this.selectedIndex = data.index
      return this.item[this.selectedIndex];
    },
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    onChange(editor) {
      console.log('onChange', editor.children)
      if (this.item.type === 3) {
        this.item.isClick = false
        this.item.briefAnswer = this.item.briefAnswer;
        // 先将p标签的结尾和开头时的数据加上换行，然后去掉所有的html标签 由于改后无法使用富文本过年，所以注释掉
        // this.item.briefAnswer = this.item.briefAnswer.replace(/<\/p><p>/g, '</p>\n<p>').replace(/<[^>]+>/g, '');
        if (this.item.briefAnswer !== '' && this.item.briefAnswer !== '<p><br></p>') {
          this.item.isClick = true // 将已做的题的状态改为true
        }
        this.optionData.push(this.item)
        // console.log('this.dataSource  ', this.dataSource);
        this.$bus.$emit('dataSource', this.dataSource)
        this.$bus.$emit('optionData', this.optionData) // 将已做的题目数据传递给top
        // console.log('已输入的选项', this.optionData);
      }
    },
    // 选择一个单选题选项
    chooseAnswer(index, id, item, isClick) {
      // 参数说明：索引，题目id，我的答案，是否选择答案
      console.log(index, id, item, isClick);
      // 单选
      if (this.item.type === 1) {
        this.item.isClick = true // 将已做的题的状态改为true
        if (this.item.isClick && this.item.id) {
          console.log(item);
          this.myOption = index // 选择的答案
          this.optionData.push(item)
        }
        console.log('this.dataSource.length', this.dataSource.length);
        this.$bus.$emit('dataSource', this.dataSource)
        this.$bus.$emit('optionData', this.optionData) // 将已做的题目数据传递给top
        console.log('已选择的选项', this.optionData);
      }
    },
    // 多选题
    // chooseAnswerChoice(id, selectedOptions, isClick) {
    chooseAnswerChoice(data, index, obj) {
      // 参数说明：题目id，我的答案，是否选择答案
      if (this.item.type === 2) {
        const selectedOption = obj.topicOptions[index].id;
        // 根据选择的选项来回显对应的数据
        if (this.selectedOptions.includes(selectedOption)) {
          console.log(`您选择了题目 ${data.id} 的选项 ${selectedOption}`);
          this.answers.push(data);
          console.log('this.answers', this.answers);

        } else {
          console.log(`您取消选择了题目 ${data.id} 的选项`);
          const indexToRemove = this.answers.findIndex(answer => answer.topicId === data.id && answer.optionId === selectedOption);
          console.log('indexToRemove', indexToRemove);
          if (indexToRemove !== -1) {
            this.answers.splice(indexToRemove, 1);
          }
        }
        if (this.selectedOptions.length > 0) {
          this.item.isClick = true // 将已做的题的状态改为true
        }
        if (this.item.isClick) {
          const exists = this.optionData.some(item => JSON.stringify(item) === JSON.stringify(obj));
          console.log('exists', exists);

          if (!exists) {
            this.optionData.push(data);
          }
        }
        this.$bus.$emit('dataSource', this.dataSource)
        this.$bus.$emit('optionData', this.optionData) // 将已做的题目数据传递给top
      }
    },
    handleDataUpdated(newData) {
      // 处理接收到的数据
      console.log('newData', newData);
      this.item = newData
      let topicType = newData
      this.$bus.$emit('topicType', topicType)
    },
  },
  beforeDestroy() {
    // 在组件销毁之前，取消事件监听，以避免内存泄漏
    this.$bus.$off('data-updated', this.handleDataUpdated);
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.box {
  padding: 0 5px;
  border: 2px solid #c1c1c1;
  background-color: #edfade;
  height: 92%;
}

.tipMsg {
  height: 26px;
  margin-top: 10px;
  border-bottom: 1px solid #c1c1c1;
  font-weight: bold;
}

.choice-Question {
  border: 2px solid #c1c1c1;
  height: 92%;
  padding: 5px;
  margin: -2px -7px;
}

/* 简答题 */
/* 上半部分 */
.top {
  height: 65%;
}

/* 头部当前题目分数和操作按钮 */
.title {
  border: 2px solid #c1c1c1;
  padding: 5px 0;
}

/* 题目内容 */
.topic {
  /* height: 85%; */
  height: 355px;
  border: 1px solid #c1c1c1;
  margin: 2px 0;
  overflow: auto;
  padding: 0 10px;
}

/* 下半部分 */
.bottom {
  display: flex;
  height: 35%;
}

.seedTopic {
  width: 50%;
  border: 1px solid #c1c1c1;
  padding: 7px;
  margin: -22px 2px 0 0;
}

.answerArea {
  width: 100%;
  border: 1px solid #c1c1c1;
  margin: -22px -1px 0px -1px;
}
</style>
